import * as React from 'react';

export const MenuGridSvg: React.SFC<{ className?: string }> = (props) => {
    return <svg className={props.className || 'menu-options-svg'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M176.8 0H59.2C26.6 0 0 26.6 0 59.2v117.6C0 209.4 26.6 236 59.2 236h117.6C209.4 236 236 209.4 236 176.8V59.2C236 26.6 209.4 0 176.8 0zM196 176.8c0 10.6-8.6 19.2-19.2 19.2H59.2C48.6 196 40 187.4 40 176.8V59.2C40 48.6 48.6 40 59.2 40h117.6C187.4 40 196 48.6 196 59.2V176.8z" />
        <path d="M452 0H336c-33.1 0-60 26.9-60 60v116c0 33.1 26.9 60 60 60h116c33.1 0 60-26.9 60-60V60C512 26.9 485.1 0 452 0zM472 176c0 11-9 20-20 20H336c-11 0-20-9-20-20V60c0-11 9-20 20-20h116c11 0 20 9 20 20V176z" />
        <path d="M176.8 276H59.2C26.6 276 0 302.6 0 335.2v117.6C0 485.4 26.6 512 59.2 512h117.6C209.4 512 236 485.4 236 452.8V335.2C236 302.6 209.4 276 176.8 276zM196 452.8c0 10.6-8.6 19.2-19.2 19.2H59.2C48.6 472 40 463.4 40 452.8V335.2C40 324.6 48.6 316 59.2 316h117.6c10.6 0 19.2 8.6 19.2 19.2V452.8z" />
        <path d="M452 276H336c-33.1 0-60 26.9-60 60v116c0 33.1 26.9 60 60 60h116c33.1 0 60-26.9 60-60V336C512 302.9 485.1 276 452 276zM472 452c0 11-9 20-20 20H336c-11 0-20-9-20-20V336c0-11 9-20 20-20h116c11 0 20 9 20 20V452z" />
    </svg>
};




