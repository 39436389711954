import { ensureTrailingSlash } from "./string-functions";
import { ArticleMetaData } from "@ViewModels";

export function createFacebookShareLink(route: string, title: string) {
  const shareUrl = `https://www.pedaldudes.com${ensureTrailingSlash(route)}%3Fsource%3Dfbshare`;
  return `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;
}


export function createTwitterShareLink(route: string, title: string) {
  const shareUrl = `https://www.pedaldudes.com${ensureTrailingSlash(route)}?source=tshare`;
  return `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(shareUrl)}`;
}

export function createEmailShareLink(route: string, title: string) {
  const shareUrl = `https://www.pedaldudes.com${ensureTrailingSlash(route)}%3Fsource%3Demail`;
  return `mailto:info@example.com?&subject=&body=${shareUrl}`;
}
