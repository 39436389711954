import * as React from 'react';
import './CopiedContent.scss';
import { childClassClosure } from '@Functions';

const baseClass = 'copied-content';
const childClass = childClassClosure(baseClass);

interface CopiedContentProps {
  src: string
  desc: string
}

const CopiedContent: React.SFC<CopiedContentProps> = (props) => {
  return <cite className={baseClass}>
    source: <a href={props.src}>{props.desc}</a>
  </cite>
}
export default CopiedContent;
