import * as React from "react";
import { BuyLinkCollection } from "@ViewModels";

export type PedalDataContextProps = {
  buyLinks: {
    [key: string]: BuyLinkCollection
  }
};

export const PedalDataContext = React.createContext<PedalDataContextProps>({
  buyLinks: {}
});