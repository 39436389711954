import * as React from "react";
import { childClassClosure } from "@Functions";
import "./ContactForm.scss";

const baseClass = 'contact-form';
const childClass = childClassClosure(baseClass);

const ContactForm: React.SFC<{}> = () => {

  return <form className={baseClass} name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
    <input type="hidden" name="form-name" value="contact" />
    <div className={childClass('field')}>
      <label htmlFor="name">Your Name</label>
      <input type="text" name="name" required />
    </div>
    <div className={childClass('field')}>
      <label htmlFor="email">Your Email</label>
      <input type="email" name="email" required />
    </div>
    <div className={childClass('field')}>
      <label htmlFor="message">Message</label>
      <textarea name="message" required></textarea>
    </div>
    <button className={childClass('submit-button')} type="submit">Send</button>
  </form>
}


export default ContactForm;
