import * as React from "react";
import { Link } from "gatsby";
import { childClassClosure } from "@Functions";
import "./PedalLinkCard.scss";
import { ArticleMetaData, Pedal } from "@ViewModels";
import LinkWrapper from "components/texty/LinkWrapper";

export const baseClass = 'pedal-link-card';
export const childClass = childClassClosure(baseClass);

interface IProps {
  pedal: Pedal;
  displayMaker?: boolean
}

const PedalLinkCard: React.SFC<IProps> = (props) => {
  return <LinkWrapper to={props.pedal.route} className={baseClass}>
    <div className={childClass('image-wrapper')}>
      <img src={props.pedal.mainImage} alt={props.pedal.name} />
    </div>
    <div className={childClass('content-wrapper')}>
      <h5 className={childClass('product-name')}>{props.pedal.name}</h5>
      {props.displayMaker == true &&
        <div className={childClass('maker-image-wrapper')}>
          <img src={props.pedal.makerImageUrl} alt={props.pedal.makerSlug} />
        </div>
      }
    </div>
  </LinkWrapper>
}

export default PedalLinkCard;
