export function deSlugName(slug: string) {
  let result = '';
  let firstLetter = true;
  for (let i = 0; i < slug.length; i++) {
    if (slug.charAt(i) == '-') {
      result += ' ';
      firstLetter = true;
    } else {
      if (firstLetter) {
        result += slug.charAt(i).toUpperCase();
        firstLetter = false;
      } else {
        result += slug.charAt(i);
      }
    }
  }
  return result;
}


/**
 * Returns null if the supplied string is null or empty (or whitespace, you get that one for free)
 */
export function isNullOrEmpty(str?: string): boolean {
  return str == null || str.length === 0 || str.trim().length == 0;
}


export function ensureTrailingSlash(url: string) {
  if (isNullOrEmpty(url)) return url;
  return url[url.length - 1] === '/' ? url : url + '/';
}


export function removeTrailingSlash(url: string) {
  if (isNullOrEmpty(url)) return url;
  return url[url.length - 1] === '/' ? url.substr(0, url.length - 1) : url;
}
