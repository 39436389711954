import React from "react"
import { Provider as StateProvider } from "unstated"

// TODO: Need to create gatsby-plugin-react-head
// import { HeadProvider } from "react-head"

export const Boot: React.SFC<{ element: any }> = ({ element }) => {
  return (
    <StateProvider>
      {element}
    </StateProvider>
  )
}
