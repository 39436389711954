import * as React from "react";
import { PedalDataContext } from "@Contexts/PedalDataContext";
import RelatedPedalsList from "./RelatedPedalsList";
import ErrorMessage from "components/messages/ErrorMessage";

interface IProps {
  number?: number
  listKey?: string;
  className?: string;
}

/**
 * This is a context-aware version of the <RelatedPedalsList> that gets the pedals array from the context
 */
export default class InlineBuyLinks extends React.Component<IProps, {}> {

  static contextType = PedalDataContext;
  context!: React.ContextType<typeof PedalDataContext>;

  constructor(props: IProps) {
    super(props);
  }

  render() {
    const listKey = this.props.listKey || `article-${(this.props.number || 1)}`;
    const pedalList = this.context.buyLinks[listKey];
    if (!pedalList) return <ErrorMessage message={`Could not match pedal list ${listKey} in ${Object.keys(this.context.buyLinks).join(',')}`} />

    return <RelatedPedalsList heading={pedalList.title} pedals={pedalList.pedals} className={this.props.className} displayMaker={true} />
  }
}

