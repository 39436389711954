// @ts-check

/**
 * Since this file is shared with NetlifyCMS it must be .jsx
 */

import React from "react"
import LinkButton from "./components/buttons/LinkButton";
import InlineBuyLinks from "components/explorers/InlineBuyLinks";
import ContactForm from "components/forms/ContactForm";
import CopiedContent from "components/texty/CopiedContent";


export const ExposedComponents = {
  Button: props => <button {...props}>{props.children}</button>,
  LinkButton: props => <LinkButton {...props}>{props.children}</LinkButton>,
  InlineBuyLinks: props => <InlineBuyLinks {...props} />,
  ContactForm: props => <ContactForm {...props} />,
  CopiedContent: props => <CopiedContent {...props} />
}


//<LinkButton to="browse-pedals" label="Browse Pedals />