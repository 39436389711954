import * as React from 'react';

export const CrossSvg: React.SFC<{ className?: string }> = (props) => {
    return <svg className={props.className || 'cross-svg'} xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 95.9 95.9">
        <path d="M62.8 48l32.5-32.5c0.8-0.8 0.8-2 0-2.8L83.3 0.6C83 0.2 82.4 0 81.9 0c-0.5 0-1 0.2-1.4 0.6L48 33.1 15.4 0.6c-0.7-0.7-2.1-0.7-2.8 0L0.6 12.6c-0.8 0.8-0.8 2 0 2.8L33.1 48 0.6 80.5c-0.8 0.8-0.8 2 0 2.8l12 12c0.4 0.4 0.9 0.6 1.4 0.6 0.5 0 1-0.2 1.4-0.6L48 62.8l32.5 32.5c0.4 0.4 0.9 0.6 1.4 0.6 0.5 0 1-0.2 1.4-0.6l12-12c0.8-0.8 0.8-2 0-2.8L62.8 48z" />
    </svg>
};



