import * as React from "react";
import { childClassClosure, baseClassAnd } from "@Functions";
import "./RelatedPedalsList.scss";
import { Pedal } from "@ViewModels";
import PedalLinkCard from "components/cards/PedalLinkCard";

const baseClass = 'related-pedals-list';
const childClass = childClassClosure(baseClass);

interface IProps {
  heading: string;
  pedals: Pedal[];
  displayMaker?: boolean;
  className?: string;
}

export default class RelatedPedalsList extends React.Component<IProps, {}> {

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }

  render() {
    return <div className={baseClassAnd(baseClass, this.props)}>
      <h3 className={childClass('heading')}>
        {this.props.heading}
      </h3>
      <ul className={childClass('list')}>
        {this.props.pedals.map((pedal, ci) =>
          <li key={ci}>
            <PedalLinkCard pedal={pedal} displayMaker={this.props.displayMaker} />
          </li>
        )}
      </ul>
    </div>
  }
}

