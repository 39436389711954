// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-guff-page-layout-tsx": () => import("/opt/build/repo/src/layouts/GuffPageLayout.tsx" /* webpackChunkName: "component---src-layouts-guff-page-layout-tsx" */),
  "component---src-layouts-maker-page-layout-tsx": () => import("/opt/build/repo/src/layouts/MakerPageLayout.tsx" /* webpackChunkName: "component---src-layouts-maker-page-layout-tsx" */),
  "component---src-layouts-category-page-layout-tsx": () => import("/opt/build/repo/src/layouts/CategoryPageLayout.tsx" /* webpackChunkName: "component---src-layouts-category-page-layout-tsx" */),
  "component---src-layouts-article-layout-tsx": () => import("/opt/build/repo/src/layouts/ArticleLayout.tsx" /* webpackChunkName: "component---src-layouts-article-layout-tsx" */),
  "component---src-layouts-default-layout-tsx": () => import("/opt/build/repo/src/layouts/DefaultLayout.tsx" /* webpackChunkName: "component---src-layouts-default-layout-tsx" */),
  "component---src-layouts-pedal-product-page-tsx": () => import("/opt/build/repo/src/layouts/PedalProductPage.tsx" /* webpackChunkName: "component---src-layouts-pedal-product-page-tsx" */),
  "component---src-layouts-home-page-tsx": () => import("/opt/build/repo/src/layouts/HomePage.tsx" /* webpackChunkName: "component---src-layouts-home-page-tsx" */),
  "component---src-pages-all-articles-tsx": () => import("/opt/build/repo/src/pages/all-articles.tsx" /* webpackChunkName: "component---src-pages-all-articles-tsx" */),
  "component---src-pages-all-reviews-tsx": () => import("/opt/build/repo/src/pages/all-reviews.tsx" /* webpackChunkName: "component---src-pages-all-reviews-tsx" */),
  "component---src-pages-browse-pedals-tsx": () => import("/opt/build/repo/src/pages/browse-pedals.tsx" /* webpackChunkName: "component---src-pages-browse-pedals-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

