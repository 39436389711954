import * as React from 'react';
import './LinkButton.scss';
import { childClassClosure } from '@Functions';
import { Link } from "gatsby";
import { ArrowSvg } from '@Svg';
import LinkWrapper from 'components/texty/LinkWrapper';

const baseClass = 'link-button';
const childClass = childClassClosure(baseClass);


interface LinkButtonProps {
  to: string;
  label: string;
}

const LinkButton: React.SFC<LinkButtonProps> = (props) => {
  return <div className={baseClass}>
    <LinkWrapper className={childClass('anchor')}
      to={props.to}>
      <span className={childClass('submit-label')}>{props.label}<ArrowSvg className={childClass('arrow')} /></span>
    </LinkWrapper>
  </div>
}
export default LinkButton;
