import * as React from 'react';
import { Link, GatsbyLinkProps } from "gatsby";
import { ensureTrailingSlash } from '@Functions';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

export default class LinkWrapper<TState> extends React.Component<GatsbyLinkProps<TState> & { href?: string }, any> {
  constructor(props) {
    super(props);
  }

  render() {
    const url = this.props.to || this.props.href;
    if (url.startsWith('https://'))
      return <OutboundLink href={url} target="_blank" className={this.props.className} onClick={this.props.onClick}>
        {this.props.children}
      </OutboundLink>

    return <Link
      className={this.props.className}
      activeClassName={this.props.activeClassName}
      activeStyle={this.props.activeStyle}
      innerRef={this.props.innerRef}
      onClick={this.props.onClick}
      to={ensureTrailingSlash(url)}>
      {this.props.children}
    </Link>
  }
}
