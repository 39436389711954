// @ts-check

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { Boot } from "./src/Boot"
//import "./src/sass/structural.scss";

export const onClientEntry = () => {
  console.log("We've started!")
}

export const onInitialClientRender = async () => {
  //TODO you can load stuff here if needs be (ie the client side search index)
  // console.log("ReactDOM.render has executed. Loading data file now");
  // const data = await fetch('/assets/data/pedals.csv');
  // const dataText = await data.text();
  // console.log(dataText);
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  console.log('new pathname', location.pathname)
  console.log('old pathname', prevLocation ? prevLocation.pathname : null)
}

export const wrapRootElement = Boot