import * as React from 'react';
import { childClassClosure } from '@Functions';

const baseClass = 'error-message';
const childClass = childClassClosure(baseClass);


interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: React.SFC<ErrorMessageProps> = (props) => {
  return <div className={baseClass}>
    {props.message}
  </div>
}
export default ErrorMessage;
