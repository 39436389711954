import * as React from 'react';
import { Colour } from '@Models';

interface IProps {
    fillStep: number;
}

export class StarSvg extends React.Component<IProps, {}> {

    public render() {
        const grey = '#d1d4d6';
        const filled = '#f0ad3a';
        const fillLeft = this.props.fillStep > 0 ? filled : grey;
        const fillRight = this.props.fillStep > 0.5 ? filled : grey;
        return <svg enableBackground="new 0 0 501.28 501.28" version="1.1" viewBox="0 0 501.28 501.28">
            <polygon points="501.28 194.37 335.26 159.33 250.64 12.27 250.64 419.77 405.54 489.01 387.56 320.29" fill={fillRight} />
            <polygon points="166.02 159.33 0 194.37 113.72 320.29 95.74 489.01 250.64 419.77 250.64 12.27" fill={fillLeft} />
        </svg>

    }
}
