export function childClassClosure(baseClass: string) {
    return (name: string, ...modifiers: string[]) => {
        const base = `${baseClass}__${name}`;
        if (modifiers.length == 0) return base;
        const modifiersString = modifiers.map(mod => `${base}--${mod}`).join(' ');
        return `${base} ${modifiersString}`;
    }
}

export function baseClassAnd(baseClass: string, andProps: { className?: string }, modifiers?: string) {
    if (!modifiers) return andProps.className ? `${andProps.className} ${baseClass}` : baseClass;
    return andProps.className ? `${andProps.className} ${baseClass} ${baseClass}--${modifiers}` : `${baseClass}--${modifiers}`;
}